<template>
  <el-container :id="data.vehicleNo" class="carDialogVue">
    <el-card class="box-card monitor-message" style="width: 350px">
      <div class="cars-info" @mousedown="mousedown">
        <i
          style="float: right; padding: 3px 0; cursor: pointer"
          @click.stop="closeDialog()"
          class="el-icon-close"
        ></i>
        <ul class="info">
          <li>
            <p>车牌号：{{ data.cph }}</p>
          </li>
          <li>
            <p>线路：{{ data.companyName }}</p>
          </li>
          <li v-if="Upgrades">
            <p>调度屏线路：{{ data.operateLineName }}</p>
          </li>
          <li>
            <p>司机：{{ data.driverName }}</p>
          </li>
          <li>
            <p>时间：{{ data.gpsTime }}</p>
          </li>
          <li v-if="Upgrades">
            <p>当前站点：{{ data.stationName }}({{ data.arriveType === 1 ? "到站" : "离站" }})</p>
          </li>
          <li>
            <p>ACC：{{ data.accStatus }}</p>
          </li>
          <li>
            <p>调度屏终端状态：{{ data.busOnlineStatus }}</p>
          </li>
          <li>
            <p>电量：{{ data.electric == -1 ? '-' : data.electric }}</p>
          </li>
          <li>
            <p>ADAS终端状态：{{ data.adasOnlineStatus }}</p>
          </li>
          <li>
            <p>速度：{{ data.velocity ? data.velocity : 0 }}公里/时</p>
          </li>
          <li>
            <p>位置：{{ data.address }}</p>
          </li>
          <li>
            <p
              class="textBlue"
              v-if="data.unProcessedAlarm"
              @click="toUndealAlarm"
            >
              报警状态：未处理报警 {{ data.unProcessedAlarm }} >>
            </p>
            <p v-else>报警状态：无</p>
          </li>
          <li>
            <p>最新报警：{{ data.latelyAlarmDate }}</p>
          </li>
          <li v-if="Upgrades">
            <el-button
              class="submit-btn"
              size="mini"
              type="primary"
              @click="changeLines"
              >切换线路</el-button
            >
          </li>
        </ul>
      </div>
    </el-card>
    <!-- 高德地图窗体-弹出弹窗选择线路上下行 -->
    <el-dialog
      title="线路切换"
      append-to-body
      :visible.sync="dialogVisible"
      width="28%"
    >
      <el-form
        :inline="true"
        :rules="rules"
        ref="form"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="92px"
      >
        <el-form-item label="线路名称：" prop="companyId">
          <el-select
            v-model="form.companyId"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in lineOptions"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="上下行：" prop="moveType">
          <el-select v-model="form.moveType" placeholder="请选择" clearable>
            <el-option label="上行" :value="0"></el-option>
            <el-option label="下行" :value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialogCar">取 消</el-button>
        <el-button type="primary" size="small" @click="handleAbnormalConfirmFun"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import {
  queryAllLineList
} from "@/api/lib/bus-api.js";
export default {
  name: "Window",
  props: {
    titlex: String,
    id: [String, Number],
    checkObj: Object,
    area: String,
    street: String,
    Upgrades: {
      type: Boolean,
      default: false
    },//是否是升级2.0显示内容
  },
  watch: {
    checkObj (curVal, oldVal) {
      if (curVal) {
        this.getData();
      }
    }
  },
  data () {
    return {
      color: "#66B1FF",
      title: "",
      data: {},
      carDteailTableVisible: false, //显示车辆更多详细信息
      status: ["车辆在线", "车辆离线", "正在行驶", "车辆异常"],
      dialogVisible: false,
      form: {
        companyId: null,
        moveType: "",
      },
      terminalPhoneNos: '',//终端号
      rules: {
        companyId: [{ required: true, message: "请选择线路", trigger: "blur" }],
        moveType: [{ required: true, message: "请选择上下行", trigger: "blur" }],
      },
      lineOptions: [],
      lineCode: "",
    };
  },
  mounted () {
    this.getData();
    this.getLineList(); //搜索框查询全部可选线路
  },

  methods: {
    //搜索框查询全部可选线路
    getLineList () {
      queryAllLineList().then(res => {
        if (res.code === 1000) {
          if (res.data.length == 0) {
            this.$message.warning("还没有线路信息，请先添加线路信息");
          } else {
            this.lineOptions = res.data;
            // this.lineCode = res.data[0].lineCode;
          }
        }
      });
    },
    handleAbnormalConfirmFun () {
      /**
       * terminalNos 终端号
       * [ lineCode,上下行，备注]
       * terminalType：2
       */
      let lineCode = this.lineOptions.find(item=>item.companyId == this.form.companyId)?.lineCode;
      if(lineCode){
        this.$refs.form.validate(valid => {
          if (valid) {
            this.$yhsdp.GBQueryChangeLine(this.terminalPhoneNos, [lineCode, this.form.moveType === 0 ? 1 : 2, '测试切换线路'], "2");//下发指令
            this.$message.success('切换成功，请稍后刷新查询!')
            this.closeDialogCar();
          }
        })
      }else{
        this.$message.warning("lineCode不存在请联系管理员！")
        return
      }
    },
    closeDialogCar () {
      this.dialogVisible = false;
      this.form = {
        companyId: null,
        moveType: "",
      }
    },
    changeLines () {
      //弹出弹窗-选择线路和上下行
      this.form = {
        companyId: null,
        moveType: "",
      }
      this.dialogVisible = true;
      this.terminalPhoneNos = this.data.deviceNo;
    },
    getData () {
      this.data = this.checkObj;
    },
    closeDialog (e) {
      this.$emit("dialogVisible");
    },
    toCarDetail () {
      this.$emit("toCarDetailDialog");
    },
    mousedown (event) {
      this.selectElement = document.getElementById(this.data.vehicleNo);
      var div1 = this.selectElement;
      this.selectElement.style.cursor = "move";
      this.isDowm = true;
      var distanceX = event.clientX - this.selectElement.offsetLeft;
      var distanceY = event.clientY - this.selectElement.offsetTop;
      document.onmousemove = function (ev) {
        var oevent = ev || event;
        div1.style.left = oevent.clientX - distanceX + "px";
        div1.style.top = oevent.clientY - distanceY + "px";
      };
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
        div1.style.cursor = "default";
      };
    },
    /**点击未处理报警 */
    toUndealAlarm () {
      this.$router.push({
        path: "alarmDeal",
        query: {
          type: "1",
          vehicleNo: this.data.vehicleNo,
          cph: this.data.cph
        }
      });
    }
  },

  filters: {
    filterCarType (item) {
      if (item == 0) {
        return "在线";
      } else if (item == 1) {
        return "离线";
      } else {
        return "异常";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.carDialogVue {
  position: absolute;
  border: 1px;
  top: 1px;
  right: 1px;
  z-index: 3999;
  .monitor-message {
    >>> .el-card__body {
      padding: 8px;
    }
    overflow: hidden;
    .cars-info {
      position: relative;
      .info {
        margin-bottom: 5px;
        .textBlue {
          color: #01adff;
          cursor: pointer;
        }
        li {
          height: 23px;
          line-height: 23px;
          letter-spacing: -0.34px;
          font-size: 14px;
          color: #666666;
          // padding-bottom: 10px;
          span {
            display: inline-block;
            letter-spacing: -0.34px;
            text-align: left;
            float: left;
            white-space: nowrap;
          }
          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .tips {
              color: #f78d42;
              display: inline-block;
            }
          }
        }
      }
      .z-henlarge {
        position: absolute;
        width: 5rem;
        height: 4rem;
        top: 2rem;
        right: 0.5rem;
        border-radius: 3px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
      }
    }
  }
}

.SchedulingPlatform {
  .carDialogVue {
    position: fixed;
  }
}
</style>

