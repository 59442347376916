<template>
  <div>
    <el-tabs
      type="border-card"
      v-model="addShiftOrTripRadio"
      @tab-click="shift2radio"
    >
      <el-tab-pane label="添加班次" name="1">
        <div class="form search-area">
          <el-form
            ref="form"
            :model="addShiftOrTripForm"
            label-position="left"
            label-width="120px"
            class="demo-form dialog-form"
            :rules="addShiftOrTripRules"
          >
            <el-form-item label="线路:" prop="line">
              <el-select
                v-model="addShiftOrTripForm.line"
                placeholder="请选择"
                @change="chooseLine"
              >
                <el-option
                  v-for="item in allLines"
                  :key="item.companyId"
                  :label="item.companyName"
                  :value="item.companyId"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="车辆:" prop="vehicle">
              <el-select
                filterable
                :filter-method="filterMethodFun"
                v-model="addShiftOrTripForm.vehicle"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in vehicleOptions"
                  :key="item.vehicleNo"
                  :label="item.cph"
                  :value="item.vehicleNo"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="驾驶员:" prop="vehicle">
              <el-select
                filterable
                :filter-method="vehicleMethodFun"
                v-model="addShiftOrTripForm.driver"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in driversOptions"
                  :key="item.credentialsNo"
                  :label="item.driverName"
                  :value="item.credentialsNo"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="发车类型:" prop="type">
              <el-radio v-model="addShiftOrTripForm.type" label="1"
                >主-副</el-radio
              >
              <el-radio
                v-model="addShiftOrTripForm.type"
                label="2"
                v-if="LineType !== 1"
                >副-主</el-radio
              >
            </el-form-item>

            <el-form-item label="发车时间:" prop="time">
              <el-time-picker
                v-model="addShiftOrTripForm.time"
                value-format="HH:mm"
                placeholder="请选择发车时间"
              ></el-time-picker>
            </el-form-item>

            <el-form-item label="运行时长(分钟):" prop="runTime" ref="runTime">
              <el-input v-model="addShiftOrTripForm.runTime"></el-input>
            </el-form-item>

            <el-form-item
              label="休息时长(分钟):"
              prop="restTime"
              ref="restTime"
            >
              <el-input v-model="addShiftOrTripForm.restTime"></el-input>
            </el-form-item>

            <el-form-item
              label="增加趟次:"
              v-if="addShiftOrTripRadio == 1"
              prop="addTrips"
            >
              <el-select
                v-model="addShiftOrTripForm.addTrips"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in addTripsOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane label="添加车次" name="2">
        <div class="form search-area">
          <el-form
            ref="form"
            :model="addShiftOrTripForm"
            label-position="left"
            label-width="120px"
            class="demo-form dialog-form"
            :rules="addShiftOrTripRules"
          >
            <el-form-item label="线路:" prop="line">
              <el-select
                v-model="addShiftOrTripForm.line"
                placeholder="请选择"
                @change="chooseLine"
              >
                <el-option
                  v-for="item in allLines"
                  :key="item.companyId"
                  :label="item.companyName"
                  :value="item.companyId"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="车辆:" prop="vehicle">
              <el-select
                filterable
                :filter-method="filterMethodFun"
                v-model="addShiftOrTripForm.vehicle"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in vehicleOptions"
                  :key="item.vehicleNo"
                  :label="item.cph"
                  :value="item.vehicleNo"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="驾驶员:" prop="vehicle">
              <el-select
                filterable
                :filter-method="vehicleMethodFun"
                v-model="addShiftOrTripForm.driver"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in driversOptions"
                  :key="item.credentialsNo"
                  :label="item.driverName"
                  :value="item.credentialsNo"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="发车类型:" prop="type">
              <el-radio v-model="addShiftOrTripForm.type" label="1"
                >主-副</el-radio
              >
              <el-radio
                v-model="addShiftOrTripForm.type"
                label="2"
                v-if="LineType !== 1"
                >副-主</el-radio
              >
            </el-form-item>

            <el-form-item label="发车时间:" prop="time">
              <el-time-picker
                v-model="addShiftOrTripForm.time"
                value-format="HH:mm"
                placeholder="请选择发车时间"
              ></el-time-picker>
            </el-form-item>

            <el-form-item label="运行时长(分钟):" prop="runTime" ref="runTime">
              <el-input v-model="addShiftOrTripForm.runTime"></el-input>
            </el-form-item>

            <el-form-item
              label="休息时长(分钟):"
              prop="restTime"
              ref="restTime"
            >
              <el-input v-model="addShiftOrTripForm.restTime"></el-input>
            </el-form-item>

            <el-form-item
              label="增加趟次:"
              v-if="addShiftOrTripRadio == 1"
              prop="addTrips"
            >
              <el-select
                v-model="addShiftOrTripForm.addTrips"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in addTripsOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div class="buttonGroup text-right tabButtonGroup">
      <el-button type="default" size="small" @click="cancleAddShiftOrTripDia"
        >取 消</el-button
      >
      <el-button
        type="primary"
        size="small"
        @click="submitShiftOrTrip"
        :disabled="!isSubmitShiftOrTripVisible"
        >确 定</el-button
      >
    </div>
  </div>
</template>
<script>
import moment from "moment";
import {
  queryAllBusLineList,
  getBusByline,
  getDriverByline,
  addCheciDispatchNew,
  addBanciDispatchNew
} from '@/api/lib/bus-api.js'
export default {
  data () {
    return {
      addShiftOrTripRadio: "1",
      isSubmitShiftOrTripVisible: false,
      addShiftOrTripForm: {
        line: "",
        date: "",
        vehicle: "",
        type: "1",
        time: "",
        runTime: "",
        restTime: "",
        addTrips: "0.5",
        driver: ""
      },
      allLines: [],
      vehicleOptions: [],
      driversOptions: [],
      LineType: 1,
      addTripsOptions: [
        { value: 0.5, label: "0.5" },
        { value: 1, label: "1" },
        { value: 1.5, label: "1.5" },
        { value: 2, label: "2" },
        { value: 2.5, label: "2.5" },
        { value: 3, label: "3" },
        { value: 3.5, label: "3.5" },
        { value: 4, label: "4" },
        { value: 4.5, label: "4.5" },
        { value: 5, label: "5" }
      ],
      addShiftOrTripRules: {
        runTime: [
          {
            validator (rule, value, callback) {
              if (/(^[0-9]\d*$)/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入大于等于0的整数"));
              }
            },
            trigger: "change"
          }
        ],
        restTime: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ]
      },
    }
  },
  watch: {
    addShiftOrTripForm: {
      handler (val) {
        // 非空校验
        if (val.line && val.vehicle && val.type && val.time && val.driver) {
          // 添加班次
          if (this.addShiftOrTripRadio == 1) {
            if (val.runTime && val.addTrips) {
              this.isSubmitShiftOrTripVisible = true;
            } else {
              this.isSubmitShiftOrTripVisible = false;
            }
          } else {
            // 添加车次
            if (val.runTime) {
              this.isSubmitShiftOrTripVisible = true;
            } else {
              this.isSubmitShiftOrTripVisible = false;
            }
          }
        } else {
          this.isSubmitShiftOrTripVisible = false;
        }
      },
      deep: true
    }
  },
  mounted () {
    this.getAllLineListFn();
  },
  methods: {
    //驾驶员模糊查询
    vehicleMethodFun (text) {
      getDriverByline({ keyWord: text }).then(res => {
        if (res.code == 1000) {
          this.driversOptions = res.data;
        }
      });
    },
    // 车辆模糊查询
    filterMethodFun (text) {
      getBusByline({ keyWord: text }).then(res => {
        if (res.code == 1000) {
          this.vehicleOptions = res.data;
        }
      });
    },
    // 所有的线路下拉菜单
    getAllLineListFn () {
      var self = this;
      queryAllBusLineList({}).then(res => {
        if (res.data.length == 0) {
          self.$message.warning("还没有线路信息，请先添加线路信息");
        } else {
          self.allLines = res.data;
        }
      });
    },
    // 添加班次/车次弹窗切换班次/车次
    shift2radio () {
      this.LineType = 1;
      this.addShiftOrTripForm = {
        line: "",
        date: "",
        vehicle: "",
        type: "1",
        time: "",
        runTime: "",
        restTime: "",
        addTrips: "0.5",
        driver: ""
      };
      this.$refs.runTime.resetField();
      this.vehicleOptions = [];
      this.driversOptions = [];
    },
    // 提交新增班次/车次表单
    submitShiftOrTrip () {
      let data = {
        addTime: moment(new Date()).format("YYYY-MM-DD"),
        beginTime: this.addShiftOrTripForm.time,
        vehicleNo: this.addShiftOrTripForm.vehicle,
        credentialsNo: this.addShiftOrTripForm.driver,
        companyId: this.addShiftOrTripForm.line,
        moveType: this.addShiftOrTripForm.type == 1 ? 2 : 1, // 发车类型，主-副(2),副-主(1)
        restTime: this.addShiftOrTripForm.restTime, // 休息时长，分钟数
        workTime: this.addShiftOrTripForm.runTime // 运行时长，分钟数
      };
      this.$refs.form.validate(valid => {
        if (valid) {
          // 添加班次
          if (this.addShiftOrTripRadio == 1) {
            data = {
              ...data,
              addNums: this.addShiftOrTripForm.addTrips
            };
            addBanciDispatchNew(data).then(res => {
              if (res.code === 1000) {
                this.$message.success("新增班次成功!");
              } else {
                this.$message.error(res.msg);
              }
            });
          }
          // 添加车次
          else {
            addCheciDispatchNew(data).then(res => {
              if (res.code === 1000) {
                this.$message.success("新增车次成功!");
              } else {
                this.$message.error(res.msg);
              }
            });
          }
          this.cancleAddShiftOrTripDia();
          this.$refs.form.clearValidate();
        }
      })

    },
    // 关闭增加班次/车次弹窗
    cancleAddShiftOrTripDia () {
      this.$emit("closeDia");
      // 重置弹窗数据
      this.addShiftOrTripRadio = "1";
      this.LineType = 1;
      this.addShiftOrTripForm = {
        line: "",
        date: "",
        vehicle: "",
        type: "1",
        time: "",
        runTime: "0",
        restTime: "0",
        addTrips: "0.5",
        driver: ""
      };
      this.vehicleOptions = [];
      this.driversOptions = [];
    },
    // 添加班次/车次选择线路时获取可选车辆信息和司机信息
    chooseLine (e) {
      // this.addShiftOrTripForm.line = e.companyId;
      this.allLines.map(item => {
        if (item.companyId === e) {
          this.LineType = item.lineType
        }
      })
      // console.log(111,e,this.addShiftOrTripForm.line)
      // 在选择线路时,先将表格中车辆和驾驶员清空
      this.addShiftOrTripForm.driver = "";
      this.addShiftOrTripForm.vehicle = "";
      if (this.addShiftOrTripForm.line) {
        const data = {
          companyId: this.addShiftOrTripForm.line
        };
        getBusByline(data).then(res => {
          if (res.code === 1000) {
            this.vehicleOptions = res.data;
          }
        });
        getDriverByline(data).then(res => {
          if (res.code === 1000) {
            this.driversOptions = res.data;
          }
        });
      }
    },
  }
}
</script>
<style lang="scss" scoped>
</style>