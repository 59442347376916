var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        { attrs: { data: _vm.busPlanList } },
        [
          _c(
            "el-table-column",
            { attrs: { prop: "time", label: _vm.busCode } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: _vm.banCiMoveType == 2 ? "down" : "up",
                  label: _vm.banCiMoveType == 2 ? "主-副" : "副-主"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.downState === 1 && _vm.banCiMoveType == 2
                          ? _c(
                              "span",
                              { staticStyle: { color: "rgb(1, 173, 255)" } },
                              [_vm._v(_vm._s(scope.row.down))]
                            )
                          : _vm._e(),
                        scope.row.downState === 0 && _vm.banCiMoveType == 2
                          ? _c("span", [_vm._v(_vm._s(scope.row.down))])
                          : _vm._e(),
                        scope.row.upState === 1 && _vm.banCiMoveType == 1
                          ? _c(
                              "span",
                              { staticStyle: { color: "rgb(1, 173, 255)" } },
                              [_vm._v(_vm._s(scope.row.up))]
                            )
                          : _vm._e(),
                        scope.row.upState === 0 && _vm.banCiMoveType == 1
                          ? _c("span", [_vm._v(_vm._s(scope.row.up))])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: _vm.banCiMoveType == 2 ? "up" : "down",
                  label: _vm.banCiMoveType == 2 ? "副-主" : "主-副"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.downState === 1 && _vm.banCiMoveType == 1
                          ? _c(
                              "span",
                              { staticStyle: { color: "rgb(1, 173, 255)" } },
                              [_vm._v(_vm._s(scope.row.down))]
                            )
                          : _vm._e(),
                        scope.row.downState === 0 && _vm.banCiMoveType == 1
                          ? _c("span", [_vm._v(_vm._s(scope.row.down))])
                          : _vm._e(),
                        scope.row.upState === 1 && _vm.banCiMoveType == 2
                          ? _c(
                              "span",
                              { staticStyle: { color: "rgb(1, 173, 255)" } },
                              [_vm._v(_vm._s(scope.row.up))]
                            )
                          : _vm._e(),
                        scope.row.upState === 0 && _vm.banCiMoveType == 2
                          ? _c("span", [_vm._v(_vm._s(scope.row.up))])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "buttonGroup text-right" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "default" },
              on: { click: _vm.cancel }
            },
            [_vm._v("关闭")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }