var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.shift2radio },
          model: {
            value: _vm.addShiftOrTripRadio,
            callback: function($$v) {
              _vm.addShiftOrTripRadio = $$v
            },
            expression: "addShiftOrTripRadio"
          }
        },
        [
          _c("el-tab-pane", { attrs: { label: "添加班次", name: "1" } }, [
            _c(
              "div",
              { staticClass: "form search-area" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "demo-form dialog-form",
                    attrs: {
                      model: _vm.addShiftOrTripForm,
                      "label-position": "left",
                      "label-width": "120px",
                      rules: _vm.addShiftOrTripRules
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "线路:", prop: "line" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            on: { change: _vm.chooseLine },
                            model: {
                              value: _vm.addShiftOrTripForm.line,
                              callback: function($$v) {
                                _vm.$set(_vm.addShiftOrTripForm, "line", $$v)
                              },
                              expression: "addShiftOrTripForm.line"
                            }
                          },
                          _vm._l(_vm.allLines, function(item) {
                            return _c("el-option", {
                              key: item.companyId,
                              attrs: {
                                label: item.companyName,
                                value: item.companyId
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "车辆:", prop: "vehicle" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              "filter-method": _vm.filterMethodFun,
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.addShiftOrTripForm.vehicle,
                              callback: function($$v) {
                                _vm.$set(_vm.addShiftOrTripForm, "vehicle", $$v)
                              },
                              expression: "addShiftOrTripForm.vehicle"
                            }
                          },
                          _vm._l(_vm.vehicleOptions, function(item) {
                            return _c("el-option", {
                              key: item.vehicleNo,
                              attrs: { label: item.cph, value: item.vehicleNo }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "驾驶员:", prop: "vehicle" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              "filter-method": _vm.vehicleMethodFun,
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.addShiftOrTripForm.driver,
                              callback: function($$v) {
                                _vm.$set(_vm.addShiftOrTripForm, "driver", $$v)
                              },
                              expression: "addShiftOrTripForm.driver"
                            }
                          },
                          _vm._l(_vm.driversOptions, function(item) {
                            return _c("el-option", {
                              key: item.credentialsNo,
                              attrs: {
                                label: item.driverName,
                                value: item.credentialsNo
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "发车类型:", prop: "type" } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "1" },
                            model: {
                              value: _vm.addShiftOrTripForm.type,
                              callback: function($$v) {
                                _vm.$set(_vm.addShiftOrTripForm, "type", $$v)
                              },
                              expression: "addShiftOrTripForm.type"
                            }
                          },
                          [_vm._v("主-副")]
                        ),
                        _vm.LineType !== 1
                          ? _c(
                              "el-radio",
                              {
                                attrs: { label: "2" },
                                model: {
                                  value: _vm.addShiftOrTripForm.type,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "type",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.type"
                                }
                              },
                              [_vm._v("副-主")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "发车时间:", prop: "time" } },
                      [
                        _c("el-time-picker", {
                          attrs: {
                            "value-format": "HH:mm",
                            placeholder: "请选择发车时间"
                          },
                          model: {
                            value: _vm.addShiftOrTripForm.time,
                            callback: function($$v) {
                              _vm.$set(_vm.addShiftOrTripForm, "time", $$v)
                            },
                            expression: "addShiftOrTripForm.time"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        ref: "runTime",
                        attrs: { label: "运行时长(分钟):", prop: "runTime" }
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.addShiftOrTripForm.runTime,
                            callback: function($$v) {
                              _vm.$set(_vm.addShiftOrTripForm, "runTime", $$v)
                            },
                            expression: "addShiftOrTripForm.runTime"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        ref: "restTime",
                        attrs: { label: "休息时长(分钟):", prop: "restTime" }
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.addShiftOrTripForm.restTime,
                            callback: function($$v) {
                              _vm.$set(_vm.addShiftOrTripForm, "restTime", $$v)
                            },
                            expression: "addShiftOrTripForm.restTime"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.addShiftOrTripRadio == 1
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "增加趟次:", prop: "addTrips" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.addShiftOrTripForm.addTrips,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "addTrips",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.addTrips"
                                }
                              },
                              _vm._l(_vm.addTripsOptions, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("el-tab-pane", { attrs: { label: "添加车次", name: "2" } }, [
            _c(
              "div",
              { staticClass: "form search-area" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "demo-form dialog-form",
                    attrs: {
                      model: _vm.addShiftOrTripForm,
                      "label-position": "left",
                      "label-width": "120px",
                      rules: _vm.addShiftOrTripRules
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "线路:", prop: "line" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            on: { change: _vm.chooseLine },
                            model: {
                              value: _vm.addShiftOrTripForm.line,
                              callback: function($$v) {
                                _vm.$set(_vm.addShiftOrTripForm, "line", $$v)
                              },
                              expression: "addShiftOrTripForm.line"
                            }
                          },
                          _vm._l(_vm.allLines, function(item) {
                            return _c("el-option", {
                              key: item.companyId,
                              attrs: {
                                label: item.companyName,
                                value: item.companyId
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "车辆:", prop: "vehicle" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              "filter-method": _vm.filterMethodFun,
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.addShiftOrTripForm.vehicle,
                              callback: function($$v) {
                                _vm.$set(_vm.addShiftOrTripForm, "vehicle", $$v)
                              },
                              expression: "addShiftOrTripForm.vehicle"
                            }
                          },
                          _vm._l(_vm.vehicleOptions, function(item) {
                            return _c("el-option", {
                              key: item.vehicleNo,
                              attrs: { label: item.cph, value: item.vehicleNo }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "驾驶员:", prop: "vehicle" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              "filter-method": _vm.vehicleMethodFun,
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.addShiftOrTripForm.driver,
                              callback: function($$v) {
                                _vm.$set(_vm.addShiftOrTripForm, "driver", $$v)
                              },
                              expression: "addShiftOrTripForm.driver"
                            }
                          },
                          _vm._l(_vm.driversOptions, function(item) {
                            return _c("el-option", {
                              key: item.credentialsNo,
                              attrs: {
                                label: item.driverName,
                                value: item.credentialsNo
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "发车类型:", prop: "type" } },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "1" },
                            model: {
                              value: _vm.addShiftOrTripForm.type,
                              callback: function($$v) {
                                _vm.$set(_vm.addShiftOrTripForm, "type", $$v)
                              },
                              expression: "addShiftOrTripForm.type"
                            }
                          },
                          [_vm._v("主-副")]
                        ),
                        _vm.LineType !== 1
                          ? _c(
                              "el-radio",
                              {
                                attrs: { label: "2" },
                                model: {
                                  value: _vm.addShiftOrTripForm.type,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "type",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.type"
                                }
                              },
                              [_vm._v("副-主")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "发车时间:", prop: "time" } },
                      [
                        _c("el-time-picker", {
                          attrs: {
                            "value-format": "HH:mm",
                            placeholder: "请选择发车时间"
                          },
                          model: {
                            value: _vm.addShiftOrTripForm.time,
                            callback: function($$v) {
                              _vm.$set(_vm.addShiftOrTripForm, "time", $$v)
                            },
                            expression: "addShiftOrTripForm.time"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        ref: "runTime",
                        attrs: { label: "运行时长(分钟):", prop: "runTime" }
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.addShiftOrTripForm.runTime,
                            callback: function($$v) {
                              _vm.$set(_vm.addShiftOrTripForm, "runTime", $$v)
                            },
                            expression: "addShiftOrTripForm.runTime"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        ref: "restTime",
                        attrs: { label: "休息时长(分钟):", prop: "restTime" }
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.addShiftOrTripForm.restTime,
                            callback: function($$v) {
                              _vm.$set(_vm.addShiftOrTripForm, "restTime", $$v)
                            },
                            expression: "addShiftOrTripForm.restTime"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.addShiftOrTripRadio == 1
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "增加趟次:", prop: "addTrips" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.addShiftOrTripForm.addTrips,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addShiftOrTripForm,
                                      "addTrips",
                                      $$v
                                    )
                                  },
                                  expression: "addShiftOrTripForm.addTrips"
                                }
                              },
                              _vm._l(_vm.addTripsOptions, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "buttonGroup text-right tabButtonGroup" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default", size: "small" },
              on: { click: _vm.cancleAddShiftOrTripDia }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                disabled: !_vm.isSubmitShiftOrTripVisible
              },
              on: { click: _vm.submitShiftOrTrip }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }