var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "carDialogVue", attrs: { id: _vm.data.vehicleNo } },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card monitor-message",
          staticStyle: { width: "350px" }
        },
        [
          _c(
            "div",
            { staticClass: "cars-info", on: { mousedown: _vm.mousedown } },
            [
              _c("i", {
                staticClass: "el-icon-close",
                staticStyle: {
                  float: "right",
                  padding: "3px 0",
                  cursor: "pointer"
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.closeDialog()
                  }
                }
              }),
              _c("ul", { staticClass: "info" }, [
                _c("li", [
                  _c("p", [_vm._v("车牌号：" + _vm._s(_vm.data.cph))])
                ]),
                _c("li", [
                  _c("p", [_vm._v("线路：" + _vm._s(_vm.data.companyName))])
                ]),
                _vm.Upgrades
                  ? _c("li", [
                      _c("p", [
                        _vm._v(
                          "调度屏线路：" + _vm._s(_vm.data.operateLineName)
                        )
                      ])
                    ])
                  : _vm._e(),
                _c("li", [
                  _c("p", [_vm._v("司机：" + _vm._s(_vm.data.driverName))])
                ]),
                _c("li", [
                  _c("p", [_vm._v("时间：" + _vm._s(_vm.data.gpsTime))])
                ]),
                _vm.Upgrades
                  ? _c("li", [
                      _c("p", [
                        _vm._v(
                          "当前站点：" +
                            _vm._s(_vm.data.stationName) +
                            "(" +
                            _vm._s(
                              _vm.data.arriveType === 1 ? "到站" : "离站"
                            ) +
                            ")"
                        )
                      ])
                    ])
                  : _vm._e(),
                _c("li", [
                  _c("p", [_vm._v("ACC：" + _vm._s(_vm.data.accStatus))])
                ]),
                _c("li", [
                  _c("p", [
                    _vm._v(
                      "调度屏终端状态：" + _vm._s(_vm.data.busOnlineStatus)
                    )
                  ])
                ]),
                _c("li", [
                  _c("p", [
                    _vm._v(
                      "电量：" +
                        _vm._s(
                          _vm.data.electric == -1 ? "-" : _vm.data.electric
                        )
                    )
                  ])
                ]),
                _c("li", [
                  _c("p", [
                    _vm._v("ADAS终端状态：" + _vm._s(_vm.data.adasOnlineStatus))
                  ])
                ]),
                _c("li", [
                  _c("p", [
                    _vm._v(
                      "速度：" +
                        _vm._s(_vm.data.velocity ? _vm.data.velocity : 0) +
                        "公里/时"
                    )
                  ])
                ]),
                _c("li", [
                  _c("p", [_vm._v("位置：" + _vm._s(_vm.data.address))])
                ]),
                _c("li", [
                  _vm.data.unProcessedAlarm
                    ? _c(
                        "p",
                        {
                          staticClass: "textBlue",
                          on: { click: _vm.toUndealAlarm }
                        },
                        [
                          _vm._v(
                            " 报警状态：未处理报警 " +
                              _vm._s(_vm.data.unProcessedAlarm) +
                              " >> "
                          )
                        ]
                      )
                    : _c("p", [_vm._v("报警状态：无")])
                ]),
                _c("li", [
                  _c("p", [
                    _vm._v("最新报警：" + _vm._s(_vm.data.latelyAlarmDate))
                  ])
                ]),
                _vm.Upgrades
                  ? _c(
                      "li",
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "submit-btn",
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.changeLines }
                          },
                          [_vm._v("切换线路")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "线路切换",
            "append-to-body": "",
            visible: _vm.dialogVisible,
            width: "28%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                rules: _vm.rules,
                model: _vm.form,
                "label-position": "left",
                "label-width": "92px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "线路名称：", prop: "companyId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.form.companyId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "companyId", $$v)
                        },
                        expression: "form.companyId"
                      }
                    },
                    _vm._l(_vm.lineOptions, function(item) {
                      return _c("el-option", {
                        key: item.companyId,
                        attrs: {
                          label: item.companyName,
                          value: item.companyId
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上下行：", prop: "moveType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.form.moveType,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "moveType", $$v)
                        },
                        expression: "form.moveType"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "上行", value: 0 } }),
                      _c("el-option", { attrs: { label: "下行", value: 1 } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.closeDialogCar } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleAbnormalConfirmFun }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }