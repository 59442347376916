var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "form search-area" },
      [
        _c(
          "el-form",
          {
            ref: "updateform",
            staticClass: "demo-form dialog-form",
            attrs: {
              model: _vm.updateform,
              "status-icon": "",
              rules: _vm.updaterules,
              "label-position": "left",
              "label-width": "102px"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "", prop: "type" } },
              [
                _c(
                  "el-radio",
                  {
                    attrs: {
                      label: "1",
                      disabled: _vm.updateform.cheCiIds.length > 1
                    },
                    model: {
                      value: _vm.updateform.type,
                      callback: function($$v) {
                        _vm.$set(_vm.updateform, "type", $$v)
                      },
                      expression: "updateform.type"
                    }
                  },
                  [_vm._v("调整时间")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: {
                      label: "2",
                      disabled: _vm.updateform.cheCiIds.length > 1
                    },
                    model: {
                      value: _vm.updateform.type,
                      callback: function($$v) {
                        _vm.$set(_vm.updateform, "type", $$v)
                      },
                      expression: "updateform.type"
                    }
                  },
                  [_vm._v("顶班")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "3" },
                    model: {
                      value: _vm.updateform.type,
                      callback: function($$v) {
                        _vm.$set(_vm.updateform, "type", $$v)
                      },
                      expression: "updateform.type"
                    }
                  },
                  [_vm._v("删除计划")]
                )
              ],
              1
            ),
            _vm.updateform.type === "1"
              ? _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "发车时间:", prop: "beginTime" } },
                      [
                        _c("el-time-picker", {
                          attrs: {
                            format: "HH:mm",
                            "value-format": "HH:mm",
                            size: "small",
                            placeholder: "选择时间"
                          },
                          model: {
                            value: _vm.updateform.beginTime,
                            callback: function($$v) {
                              _vm.$set(_vm.updateform, "beginTime", $$v)
                            },
                            expression: "updateform.beginTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.updateform.type === "2"
              ? _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "方式", prop: "classType" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择方式" },
                            model: {
                              value: _vm.updateform.classType,
                              callback: function($$v) {
                                _vm.$set(_vm.updateform, "classType", $$v)
                              },
                              expression: "updateform.classType"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "车人并换", value: "1" }
                            }),
                            _c("el-option", {
                              attrs: { label: "司机顶班", value: "2" }
                            }),
                            _c("el-option", {
                              attrs: { label: "车辆顶班", value: "3" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "现车辆", prop: "oldVehicleNo" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.updateform.oldVehicleNo,
                              callback: function($$v) {
                                _vm.$set(_vm.updateform, "oldVehicleNo", $$v)
                              },
                              expression: "updateform.oldVehicleNo"
                            }
                          },
                          _vm._l(_vm.nowBusByline, function(item) {
                            return _c("el-option", {
                              key: item.vehicleNo,
                              attrs: { label: item.cph, value: item.vehicleNo }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "现司机", prop: "oldCredentialsNo" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.updateform.oldCredentialsNo,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.updateform,
                                  "oldCredentialsNo",
                                  $$v
                                )
                              },
                              expression: "updateform.oldCredentialsNo"
                            }
                          },
                          _vm._l(_vm.nowDriverByline, function(item) {
                            return _c("el-option", {
                              key: item.credentialsNo,
                              attrs: {
                                label: item.driverName,
                                value: item.credentialsNo
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm.updateform.classType != "2"
                      ? _c(
                          "el-form-item",
                          {
                            attrs: { label: "顶班车辆", prop: "newVehicleNo" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  disabled: _vm.updateform.classType == "2"
                                },
                                model: {
                                  value: _vm.updateform.newVehicleNo,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.updateform,
                                      "newVehicleNo",
                                      $$v
                                    )
                                  },
                                  expression: "updateform.newVehicleNo"
                                }
                              },
                              _vm._l(_vm.allDingBusByline, function(item) {
                                return _c("el-option", {
                                  key: item.vehicleNo,
                                  attrs: {
                                    label: item.cph,
                                    value: item.vehicleNo
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.updateform.classType != "3"
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "顶班司机",
                              prop: "newCredentialsNo"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                model: {
                                  value: _vm.updateform.newCredentialsNo,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.updateform,
                                      "newCredentialsNo",
                                      $$v
                                    )
                                  },
                                  expression: "updateform.newCredentialsNo"
                                }
                              },
                              _vm._l(_vm.allDriverByline, function(item) {
                                return _c("el-option", {
                                  key: item.credentialsNo,
                                  attrs: {
                                    label: item.driverName,
                                    value: item.credentialsNo
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "600px" },
                        attrs: { label: "车次", prop: "checi" }
                      },
                      [
                        _c(
                          "el-table",
                          {
                            staticClass: "basicBox",
                            attrs: { data: _vm.dingAllList, stripe: "" },
                            on: { "selection-change": _vm.getDingCheckedData }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { type: "selection", width: "55" }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "beginTime", label: "计划发车" }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "endTime", label: "计划到达" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("filterDingArrive")(
                                              scope.row
                                            )
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2946018485
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "moveType", label: "发车类型" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.updateform.type === "3"
              ? _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "变更说明:", prop: "natureState" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择变更说明" },
                            model: {
                              value: _vm.updateform.natureState,
                              callback: function($$v) {
                                _vm.$set(_vm.updateform, "natureState", $$v)
                              },
                              expression: "updateform.natureState"
                            }
                          },
                          _vm._l(_vm.natureOptions, function(item) {
                            return _c("el-option", {
                              key: item.code,
                              attrs: { label: item.desc, value: item.code }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注:", prop: "remark" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.updateform.remark,
                            callback: function($$v) {
                              _vm.$set(_vm.updateform, "remark", $$v)
                            },
                            expression: "updateform.remark"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "buttonGroup text-right" },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "default" },
            on: { click: _vm.cancel }
          },
          [_vm._v("取 消")]
        ),
        _vm.updateform.type === "1"
          ? _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.changeTime }
              },
              [_vm._v("确 定")]
            )
          : _vm._e(),
        _vm.updateform.type === "2"
          ? _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.updateShift }
              },
              [_vm._v("保存")]
            )
          : _vm._e(),
        _vm.updateform.type === "3"
          ? _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.submitDelPlan }
              },
              [_vm._v("确 定")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }