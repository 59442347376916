<template>
  <div>
    <el-table :data="busPlanList">
        <el-table-column prop="time" :label="busCode">
          <el-table-column
            :prop="banCiMoveType == 2 ? 'down':'up'"
            :label="banCiMoveType == 2 ? '主-副':'副-主'"
          >
            <template slot-scope="scope">
              <span
                v-if="scope.row.downState ===1 && banCiMoveType == 2"
                style="color:rgb(1, 173, 255)"
              >{{scope.row.down}}</span>
              <span v-if="scope.row.downState ===0 && banCiMoveType == 2">{{scope.row.down}}</span>
              <span
                v-if="scope.row.upState ===1 && banCiMoveType == 1"
                style="color:rgb(1, 173, 255)"
              >{{scope.row.up}}</span>
              <span v-if="scope.row.upState ===0 && banCiMoveType == 1">{{scope.row.up}}</span>
            </template>
          </el-table-column>
          <el-table-column
            :prop="banCiMoveType == 2 ? 'up':'down'"
            :label="banCiMoveType == 2 ? '副-主':'主-副'"
          >
            <template slot-scope="scope">
              <span
                v-if="scope.row.downState ===1 && banCiMoveType == 1"
                style="color:rgb(1, 173, 255)"
              >{{scope.row.down}}</span>
              <span v-if="scope.row.downState ===0 && banCiMoveType == 1">{{scope.row.down}}</span>
              <span
                v-if="scope.row.upState ===1 && banCiMoveType == 2"
                style="color:rgb(1, 173, 255)"
              >{{scope.row.up}}</span>
              <span v-if="scope.row.upState ===0 && banCiMoveType == 2">{{scope.row.up}}</span>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <div class="buttonGroup text-right">
        <el-button size="small" type="default" @click="cancel">关闭</el-button>
      </div>
  </div>
</template>
<script>
export default {
  props:{
    busPlanList:{
      type:Array,
      default:()=>[]
    },
    busCode:{
      type:String,
      default:''
    },
    banCiMoveType:{
      type:Number,
      default:0,
    }
  },
  data(){
    return{

    }
  },
  methods:{
    cancel () {
      this.$emit("closeDia");
    },
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-table__empty-block{
  min-height: 160px;
}
</style>