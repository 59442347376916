<template>
  <div>
     <div class="form search-area">
        <el-form
          :model="updateform"
          status-icon
          :rules="updaterules"
          label-position="left"
          ref="updateform"
          label-width="102px"
          class="demo-form dialog-form"
        >
          <el-form-item label prop="type">
            <el-radio
              v-model="updateform.type"
              label="1"
              :disabled="updateform.cheCiIds.length > 1"
            >调整时间</el-radio>
            <el-radio
              v-model="updateform.type"
              label="2"
              :disabled="updateform.cheCiIds.length > 1"
            >顶班</el-radio>
            <el-radio v-model="updateform.type" label="3">删除计划</el-radio>
          </el-form-item>

          <div v-if="updateform.type  === '1'">
            <el-form-item label="发车时间:" prop="beginTime">
              <el-time-picker
                format="HH:mm"
                value-format="HH:mm"
                size="small"
                v-model="updateform.beginTime"
                placeholder="选择时间"
              ></el-time-picker>
            </el-form-item>
          </div>

          <div v-if="updateform.type  === '2'">
            <el-form-item label="方式" prop="classType">
              <!-- <el-select
                v-model="updateform.classType"
                placeholder="请选择方式"
                @change="checBusDriverByline"
              > -->
              <el-select
                v-model="updateform.classType"
                placeholder="请选择方式"
              >
                <el-option label="车人并换" value="1"></el-option>
                <el-option label="司机顶班" value="2"></el-option>
                <el-option label="车辆顶班" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="现车辆" prop="oldVehicleNo">
              <el-select :disabled="true" v-model="updateform.oldVehicleNo">
                <el-option
                  v-for="item in nowBusByline"
                  :key="item.vehicleNo"
                  :label="item.cph"
                  :value="item.vehicleNo"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="现司机" prop="oldCredentialsNo">
              <el-select :disabled="true" v-model="updateform.oldCredentialsNo">
                <el-option
                  v-for="item in nowDriverByline"
                  :key="item.credentialsNo"
                  :label="item.driverName"
                  :value="item.credentialsNo"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="顶班车辆" v-if="updateform.classType != '2'" prop="newVehicleNo">
              <el-select :disabled="updateform.classType == '2'" v-model="updateform.newVehicleNo">
                <el-option
                  v-for="item in allDingBusByline"
                  :key="item.vehicleNo"
                  :label="item.cph"
                  :value="item.vehicleNo"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="顶班司机" v-if="updateform.classType != '3'" prop="newCredentialsNo">
              <el-select v-model="updateform.newCredentialsNo">
                <el-option
                  v-for="item in allDriverByline"
                  :key="item.credentialsNo"
                  :label="item.driverName"
                  :value="item.credentialsNo"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车次" prop="checi" style="width:600px">
              <el-table
                class="basicBox"
                :data="dingAllList"
                @selection-change="getDingCheckedData"
                stripe
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="beginTime" label="计划发车"></el-table-column>
                <el-table-column prop="endTime" label="计划到达">
                  <template slot-scope="scope">{{scope.row | filterDingArrive}}</template>
                </el-table-column>
                <el-table-column prop="moveType" label="发车类型"></el-table-column>
              </el-table>
            </el-form-item>
          </div>

          <div v-if="updateform.type  === '3'">
            <el-form-item label="变更说明:" prop="natureState">
              <el-select v-model="updateform.natureState" placeholder="请选择变更说明">
                <el-option
                  v-for="item in natureOptions"
                  :key="item.code"
                  :label="item.desc"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注:" prop="remark">
              <el-input v-model="updateform.remark"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>

      <div class="buttonGroup text-right">
        <el-button size="small" type="default" @click="cancel">取 消</el-button>
        <el-button
          size="small"
          type="primary"
          v-if="updateform.type  === '1'"
          @click="changeTime"
        >确 定</el-button>
        <el-button size="small" type="primary" v-if="updateform.type  === '2'" @click="updateShift">保存</el-button>
        <el-button
          size="small"
          type="primary"
          v-if="updateform.type  === '3'"
          @click="submitDelPlan"
        >确 定</el-button>
      </div>
  </div>
</template>
<script>
import { updateWorkTripTimeNew,//修改未来计划
  updateWorkTripTime,
topShiftDispatchNew,
queryTopShiftChiCiDispatchNew,
queryTopShiftDriverNew,
queryTopShiftVehicleNew, 
deleteCheCiByIdsNew,
queryNatureEnum
} from "@/api/lib/bus-api.js";
import moment from "moment";
export default {
  props:{
    updateform:{
      type:Object,
      default:()=>{}
    },
    nowBusByline:{
      type:Array,
      default:()=>[]
    },
    nowDriverByline:{
      type:Array,
      default:()=>[]
    },
    isPlan:{
      type:Boolean,
      default:true
    }
  },
  data(){
    return{
      dingCheCiIds: [],
      allDingBusByline:[],
      allDriverByline:[],
      dingAllList:[],
      natureOptions:[],
      workDay:moment(new Date()).format("YYYY-MM-DD"),
      updaterules: {
        lineId: [{ required: true, message: "不能为空", trigger: "change" }],
        oldCredentialsNo: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        oldVehicleNo: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        newCredentialsNo: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        classType: [{ required: true, message: "不能为空", trigger: "change" }],
        newVehicleNo: [
          { required: true, message: "不能为空", trigger: "change" }
        ]
      },
    }
  },
  filters:{
    filterDingArrive(item) {
      let timestamp =
        new Date(new Date(`2019-01-01 ${item.beginTime}:00`)).getTime() +
        60000 * item.workTime;
      return moment(timestamp).format("HH:mm");
    }
  },
  mounted(){
    this.getDingListFn()
    this.getqueryNatureEnum();
    this.checBusDriverByline();//查看可选车辆和司机
  },
  methods:{
    //删除可选项
    getqueryNatureEnum(){
      queryNatureEnum().then(res=>{
        this.natureOptions = res.data
      })
    },
    cancel () {
      this.$emit("closeDia");
    },
    // 删除计划确定按钮
    submitDelPlan() {
      var self = this;
      deleteCheCiByIdsNew({
        cheCiIds: this.updateform.cheCiIds,
        natureState: this.updateform.natureState,
        remark: this.updateform.remark
      })
        .then(res => {
          if (res.code === 1000) {
            this.$message.success("操作成功");
            this.$emit("closeDia");
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // 根据驾驶员、车辆和日期查询车次
    getDingListFn() {
      var self = this;
      var data = {
        workDay:this.workDay,
        credentialsNo: self.updateform.oldCredentialsNo,
        vehicleNo: self.updateform.oldVehicleNo
      };
      queryTopShiftChiCiDispatchNew(data).then(res => {
        self.dingAllList = res.data;
        self.dingAllList.map(item => {
          if (item.moveType == 2) {
            item.moveType = "主-副";
          } else {
            item.moveType = "副-主";
          }
        });
      });
    },
    // 顶班选中checkbox
    getDingCheckedData(val) {
      this.dingCheCiIds = val.map(ele => ele.cheCiId);
    },
    // 显示可选司机和车辆列表
    checBusDriverByline() {
      var self = this;
      var data = {
        workTime: this.workDay,
        cheCiIds: self.updateform.cheCiIds
      };
      self.allDingBusByline = [];
      self.allDriverByline = [];
      queryTopShiftVehicleNew(data).then(res => {
        self.allDingBusByline = res.data;
        if (res.data.length == 0) {
          self.$message.warning("当前时间无车辆可选择");
        }
      });
      queryTopShiftDriverNew(data).then(res => {
        self.allDriverByline = res.data;
        if (res.data.length == 0) {
          self.$message.warning("当前时间无司机可选择");
        }
      });
    },
    // 调整时间
    changeTime() {
      const queryData = {
        newBeginTime: this.updateform.beginTime,
        cheCiId: this.updateform.cheCiIds[0]
      }
      let queryPath = null;
      queryPath = this.isPlan ? updateWorkTripTimeNew : updateWorkTripTime;
      queryPath(queryData)
        .then(res => {
          if (res.code === 1000) {
            this.$message.success("操作成功");
            this.$emit("closeDia");
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    updateShift() {
      var self = this;
      self.$refs.updateform.validate(valid => {
        if (valid) {
          self.updateform.cheCiIds = self.dingCheCiIds;
          var data = {
            cheCiIds: self.updateform.cheCiIds,
            classType: self.updateform.classType,
            newVehicleNo: self.updateform.newVehicleNo,
            newCredentialsNo: self.updateform.newCredentialsNo,
            workDay: this.workDay
          };
          topShiftDispatchNew(data)
            .then(res => {
              if (res.code === 1000) {
                this.$message.success("操作成功");
                this.$emit("closeDia");
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>

</style>